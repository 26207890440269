import { render, staticRenderFns } from "./Workspace.vue?vue&type=template&id=7dedd543&scoped=true"
import script from "./Workspace.vue?vue&type=script&lang=js"
export * from "./Workspace.vue?vue&type=script&lang=js"
import style0 from "./Workspace.vue?vue&type=style&index=0&id=7dedd543&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dedd543",
  null
  
)

export default component.exports